import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

  filter = new FormGroup({
    organization: new FormControl(),
    school: new FormControl(),
    major: new FormControl(),
    technologies: new FormControl(),
    level: new FormControl(),
    rating: new FormControl()
  })

  submit() {
    // console.log(
    //   this.filter.get('organization').value + " : " +
    //   this.filter.get('school').value + " : " +
    //   this.filter.get('major').value + " : " +
    //   this.filter.get('technologies').value + " : " +
    //   this.filter.get('level').value + " : " +
    //   this.filter.get('rating').value
    // );
  }

}
